import axios from "axios";
import {axiosParams, getUserToken} from "../../../common/utils";
import {ACCESSIONING_DRAFT_API_URL} from "./draft";

/**
 * Fetch user's accessioning draft from db
 * @returns {Promise} - A promise that resolves to the draft data or null if no draft is found (or error)
 */
const fetchDraft = () => {
  return new Promise((resolve, reject) => {
    const token = getUserToken();
    if (!token) {
      reject(new Error("No user token available"));
      return;
    }

    axios(axiosParams(ACCESSIONING_DRAFT_API_URL, token, null, 'GET'))
      .then((response) => {
        if (response.data) {
          const snapshotData = JSON.parse(response.data.snapshot);
          const formValues = snapshotData.formValues;
          const draftData = {
            accessioningId: snapshotData.accessioningId, // if null, create batch; if set, editing batch
            accessioningType: snapshotData.accessioningType,
            datetime: response.data.created_at,
            clinicId: snapshotData.clinic_id,
            clinicName: response.data.clinic_name ? response.data.clinic_name : 'Not Provided',
            formValues: formValues,
            filesUploaded: snapshotData.filesUploaded,
            patientFirstName: formValues.patient_first_name ? formValues.patient_first_name : '(no first name)',
            patientLastName: formValues.patient_last_name ? formValues.patient_last_name : '(no last name)',
            patientDOB: formValues.patient_dob ? formValues.patient_dob : '',
            sampleCount: formValues.embryo_samples ? formValues.embryo_samples.length : 0,
            note: formValues.note ? formValues.note : '',
            number_of_samples: formValues.number_of_samples ? formValues.number_of_samples : ''
          };

          resolve(draftData);
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        if (error.response.status === 404)
          resolve(null); // No draft found
        else
          reject(error);
      });
  });
};

export default fetchDraft;