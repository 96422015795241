import axios from "axios";
import {axiosParams, getUserToken} from "./utils";

const fetchAccessioning = (accessioningId) => {
  return new Promise((resolve, reject) => {
    const token = getUserToken();
    if (!token) {
      reject(new Error("No user token available"));
      return;
    }

    axios(axiosParams(`accessioning/${accessioningId}`, token, null, 'GET'))
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default fetchAccessioning;