import React, { useRef } from 'react';
import {Button} from "reactstrap";
import {accessioning_sample_issues} from "../../common/accessioning_sample_issues";


/**
 * EmbryoIssueSelection component - let user choose one or more potential issues with provided samples
 * @param {function} getIssueSelectionParams - function to retrieve the issueSelectionParams object from BatchEntryForm (see below)
 */
const EmbryoIssueSelection = ({getIssueSelectionParams}) => {
  const containerRef = useRef(null);

  // Get the parameters sent to this dialog:
  //    valuesEmbryoSamples - Formik values.embryo_samples array (issue is an element in embryo_samples object)
  //    embryoSampleIndex - index of which row in embryo_samples array are we modifying issue for
  //    callbackFunction - function to call when done.  Resets issueSelectionParams and closes this dialog
  //    setFieldValue - Formik local function to set new value of issue
  const issueSelectionParams = getIssueSelectionParams();
  if (!issueSelectionParams) return null;
  const {valuesEmbryoSamples, embryoSampleIndex, callbackFunction, setFieldValue} = issueSelectionParams;

  // currentIssueCodes = array of all issue codes for this embryo sample
  const currentIssueCodes = valuesEmbryoSamples[embryoSampleIndex].issue.split('|');

  // when user has clicked save button ...
  const handleSave = (e) => {
    e.preventDefault();
    if (!containerRef.current) return;

    // checkedValues = array containing issue codes that are now checked
    const allCheckedBoxes = containerRef.current.querySelectorAll('input[type=checkbox]:checked');
    const checkedValues = Array.from(allCheckedBoxes).map((checkbox) => checkbox.value);

    // set Formik values.embryo_samples[index].issue to new issue value, then exit
    const issues = checkedValues.join('|');
    const newArray = [...valuesEmbryoSamples];
    newArray[embryoSampleIndex].issue = issues;
    setFieldValue('embryo_samples', newArray, false);
    callbackFunction();
  };

  const keyUpHandler = (event) => {
    if (event.key === 'Enter') {
      handleSave(event);
    } else if (event.key === 'Escape') {
      event.preventDefault()
      callbackFunction();
    }
    // else pass through key
  };

  // render
  return (
    <div>
      <h2>Select Sample Issues</h2>
      <button style={{
          position: "absolute",
          top: "0",
          right: "3px",
          cursor: "pointer",
          fontSize: "1.2rem",
          border: "0",
          backgroundColor: "transparent"
        }} onClick={() => callbackFunction()}
      >
        &times;
      </button>
      <div ref={containerRef} onKeyUp={keyUpHandler}>
        {Object.entries(accessioning_sample_issues).map(([issueCode, issueDescription], index) => (
          <div key={issueCode}
            style={{backgroundColor: index % 2 === 0 ? '#ffffff' : '#f3f3f3'}}
          >
            <label style={{cursor: "pointer"}}>
              <input
                name={`issue_${issueCode}`}
                type="checkbox"
                value={issueCode}
                className="border-0 p-5"
                defaultChecked={currentIssueCodes.includes(issueCode)}
                style={{verticalAlign: 'middle', marginRight: '5px'}}
              />
              {issueDescription}
            </label>
          </div>
        ))}

        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            padding: "1rem"
          }}
        >
          <div style={{ flex: 1, textAlign: "center" }}>
            <Button type="button" color="success" className='mr-3' onClick={handleSave}>
              Save
            </Button>
          </div>

          {/* Right-justify Cancel button */}
          <div>
            <Button type="button" color="warning" className='mr-3' onClick={callbackFunction}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default EmbryoIssueSelection;
