import React, {useState, useRef, useEffect} from "react";
import {Button, Col, Row} from "reactstrap";
import TextareaAutosize from 'react-textarea-autosize';
import ClinicDropdown from "./ClinicDropdown";
import EmbryoIssueSelection from "./EmbryoIssueSelection";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Formik, Form, Field, ErrorMessage, FieldArray} from "formik";
import axios from "axios";
import {
  validateRequiredRecentPastDate,
  validateRequiredString
} from "../../../../utils/Formik2/validators";
import {linkAccessioningBatchToCase} from "../../common/LinkAccessioningBatchToCase";
import {
  axiosParams,
  getFileTypeFromFilename, viewS3File,
  displayDateMMDDYY, todayLocalDateYYYYMMDD, isoUTCtoLocalDateTime,
  displayCount,
  getUserToken,
  acceptedFileTypeRegex,
  acceptedFileExtensions,
  acceptedFileTypesString,
  CaseLink,
  ShowChildren,
} from "../../common/utils";
import {
  ACCESSIONING_TYPE__CLINICAL, ACCESSIONING_TYPE__OTHER_DNA, ACCESSIONING_TYPE__NONE,
  accessioningTypeColorBg,
  accessioningTypeDisplayValues
} from "../../common/accessioningTypes";
import {
  ACCESSIONING_RELATIONSHIP__AUNT,
  ACCESSIONING_RELATIONSHIP__BROTHER,
  ACCESSIONING_RELATIONSHIP__COUSIN_FEMALE,
  ACCESSIONING_RELATIONSHIP__COUSIN_MALE,
  ACCESSIONING_RELATIONSHIP__DAUGHTER,
  ACCESSIONING_RELATIONSHIP__FATHER,
  ACCESSIONING_RELATIONSHIP__MOTHER,
  ACCESSIONING_RELATIONSHIP__SISTER,
  ACCESSIONING_RELATIONSHIP__SON,
  ACCESSIONING_RELATIONSHIP__UNCLE,
  ACCESSIONING_SUBJECT__PARTNER,
  ACCESSIONING_SUBJECT__PATIENT,
  ACCESSIONING_SUBJECT__RELATIVE,
  ACCESSIONING_SUBJECT__DONOR,
  ACCESSIONING_SUBJECT__UNKNOWN
} from "../../common/accessioningRelatives";
import {Dialog} from "@blueprintjs/core";
import {
  ACCESSIONING_STATUS__ACCESSIONED,
  ACCESSIONING_STATUS__RECEIVED
} from "../../common/accessioningStatus";
import {accessioning_sample_issues} from "../../common/accessioning_sample_issues";
import fetchDraft from "./Draft/FetchDraft";
import saveDraft from "./Draft/SaveDraft";
import fetchAccessioning from "../../common/fetchAccessioning";
import deleteDraft from "./Draft/DeleteDraft";

//
// A note on variable case [Seth]
// camelCase is standard for variable naming (e.g. filesToUpload), but
// when a variable is used to hold data to/from db tables, it may be named in snake_case instead (e.g. embryo_number)
// to minimize errors/confusion.

/**
 * Component to Display Accessioning-Batch entry/edit/display form for all accessioning types.
 * Called within <Dialog> of AllTab/index.
 * Props:
 *   {string|number} accessioningTypeOrId -
 *          when new accessioning, this is accessioning type -- 'clinical', 'dna', ... - see accessioningTypes.js
 *          when viewing/editing existing accessioning type, this is id of accessioning batch
 *   {function} onClose - function that closes this dialog
 * @returns {JSX.Element}
 */
export default function BatchEntryForm(props) {
  // ID of accessioning batch being edited.  Null when new accessioning batch being created.
  const [editAccessioningId, setEditAccessioningId] = useState(
    typeof props.accessioningTypeOrId === 'number' ? props.accessioningTypeOrId : null
  );

  // Data content of accessioning batch being edited.
  const [editBatchData, setEditBatchData] = useState({});

  // Type of accessioning batch.  Initially '' when creating new batch.
  const [accessioningType, setAccessioningType] = useState(
    typeof props.accessioningTypeOrId === 'string' ? props.accessioningTypeOrId : ACCESSIONING_TYPE__NONE );

  // Clinic (provider) selected by user - object {id: provider id, company_name: clinic name}
  // Clinic id 0 signifies "Not Provided"
  const [clinic, setClinic] = useState(null);

  // Object holding parameters for EmbryoIssueSelection dialog.  See openSampleIssueDialog.
  const [issueSelectionParams, setIssueSelectionParams] = useState(null);

  // Modal dialog to show alerts
  // show/hide error alert modal.
  const [showErrorAlertModal, setShowErrorAlertModal] = useState(false);
  // message to show within error alert modal
  const [modalAlertMessage, setModalAlertMessage] = useState("");

  // Modal that displays case-matchup to accessioning
  const [modalMatchMessage, setModalMatchMessage] = useState(""); // error/no-match message
  const [modalDisplayMatchedCaseId, setModalDisplayMatchedCaseId] = useState("");
  const [modalMatchingCaseUrl, setModalMatchingCaseUrl] = useState("");

  // Array of files that have been uploaded.  Each array element is {file_name, url, ext, datetime}:
  //      file_name - bare file name e.g. 'important-report.pdf'
  //      url - file's address/nametag within S3.  i.e. what's used in browser to access file directly.
  //      ext - file's extension/type.  E.g. pdf, jpg, doc, xlsx ...
  //      datetime - datetime string file was uploaded, in iso format.  E.g. '2024-10-14 09:50 PM'
  const [filesUploaded, setFilesUploaded] = useState([]);

  // Draft data field content to save/retrieve
  const [draftData, setDraftData] = useState(null);
  // disable SAVE DRAFT button while draft saving in progress
  const [saveDraftInProgress, setSaveDraftInProgress] = useState(false);
  // Display "Draft Saved" message to user (true for 3 seconds, then false)
  const [displayDraftSaved, setDisplayDraftSaved] = useState(false);

  // Default initial values for form.  Overridden when editing existing batch, or after loading draft

  const [formikEmbryonicInitialValues, setFormikEmbryonicInitialValues] = useState({
    received_at: todayLocalDateYYYYMMDD(),  // default sample-received to today's date
    patient_first_name: "",
    patient_last_name: "",
    patient_dob: null,  // DJANGO CREATE fails if empty string ""
    buffer_lot: "",
    accessioning_status: ACCESSIONING_STATUS__ACCESSIONED,
    note: "",
    number_of_embryos: 0,
    embryo_samples: []
  });

  const [formikOtherDNAInitialValues, setFormikOtherDNAInitialValues] = useState({
    test_type: "notprovided",
    received_at: todayLocalDateYYYYMMDD(),  // default sample-received to today's date
    patient_first_name: "",
    patient_last_name: "",
    patient_dob: null,
    note: '',
    subject_role: '',
    tube_id: '',
    accessioning_status: ACCESSIONING_STATUS__ACCESSIONED,
  });

  // when true, all initialization done and accessioning batch form displays.  False, when still loading, etc.
  const [initializationComplete, setInitializationComplete] = useState(false);

  // Call to close this BatchEntryForm dialog when done
  const {onClose} = props;

  // Handle to the <input type="file"...> needed to make the file-upload work (but don't want user to see it)
  const hiddenInputFileButtonRef = useRef(null);

  // Prevent another upload from starting while file-upload in progress
  const [fileUploadsInProgress, setFileUploadsInProgress] = useState(false);

  // handle to the dialog that asks Resume/Discard/Quit for drafts
  const draftDialogRef = useRef(null);
  // promise that caller will resolve to RESUME, DISCARD or QUIT when invoking draft dialog
  const resolveDraftDialogRef = useRef(null);

  // Dialog - Ask User "Link Dialog to Case?" when matching case found on submit
  // handle to the dialog that asks Yes/No/Cancel
  const linkDialogRef = useRef(null);  // ref to link dialog
  // promise that caller resolves to YES, NO or CANCEL when invoking linkDialog
  const resolveLinkDialogRef = useRef(null);  // ref to resolve link dialog promise

  // handle to dialog that displays clinic selector
  const clinicDialogRef = useRef(null);

  // handle to dialog that displays sample issue choices
  const issueDialogRef = useRef(null);

  // Show/hide indicator overlay when saving an accessioning
  const [showSavingOverlay, setShowSavingOverlay] = useState(false);

  //
  // Code Sections
  //     0. Misc functions
  //     I. Draft and Batch Initialization
  //    II. File-Upload Related (uploading, viewing, ...)
  //   III. Submit, Save and Matchup code
  //    IV. Embryonic Form
  //     V. Other DNA Form
  //    VI. Rendering
  //

  //
  // 0. Misc functions
  //

  // when true, this dialog was invoked to create a new accessioning
  const creatingNewAccessioningBatch = () => editAccessioningId === null;
  // when true, this dialog was invoked to edit an existing accessioning
  const editingAccessioningBatch = () => editAccessioningId !== null;

  /**
   * get user's auth token needed for API calls.
   * @param {boolean} silent - display alert if unable to get token?  True, remain silent.  False, display alert.
   * @returns {string|false} - returns token string.  If unable to get token, returns false.
   */
  const getAuthToken = (silent=false) => {
    const token = getUserToken();
    if (!token) {
      if (!silent) return alertAndFail(`Authorization lost.  Try logging back in.`);
      return false;
    }
    return token;
  }

  /**
   * Display error alert Dialog showing given message.
   * @param {string} message - message to display
   * @returns {boolean} - always false, as a convenience for calling function
   */
  const alertAndFail = message => {
    setModalAlertMessage(message);
    setShowErrorAlertModal(true);
    return false;
  }

  /**
   * validator for embryo_number in Formik form( validate={validateEmbryoNumber} )
   * @param value - value to validate.  Must either be a valid number or 'NP'
   * @returns {string} - "" if field is valid; error-string if not
   */
  const validateEmbryoNumber = value => {
    if (value === null || value === undefined || value.trim().length === 0) return 'Valid number or NP required';
    if (value === 'NP') return "";
    const m = value.match(/^[1-9]\d*$/);
    if (!m) return 'Valid number or NP required';
    return "";
  }

  /**
   * Return object fields for new embryonic sample line.  (e.g. when user clicks [+] to add new sample)
   * @returns {{embryo_number: string, tube_label: string, tissue_type: string, issue: string, QC: string, on_hold: boolean, note: string}}
   */
  const newSampleLineObj = () => {
    return {
      embryo_number: "",
      tube_label: "",
      tissue_type: "",
      issue: "",
      QC: "accept",
      on_hold: false,
      note: ""
    }
  };

  /**
   * Remove given file from S3 bucket
   * @param type
   * @param fileUploadedItem     {file_name, url, ext, datetime}  see filesUploaded above
   * @returns {Promise<unknown>}
   */
  const deleteUploadedFile = (type, fileUploadedItem) => {
    return new Promise((resolve, reject) => {
      const token = getUserToken();
      if (!token) {
        reject(new Error('No authorization token available'));
        return;
      }

      axios(axiosParams('file/0', token, {
          type: type,
          file: JSON.stringify(fileUploadedItem),  // content of file to delete
          accessioning: 'standalone'
        },
        'DELETE'
      ))
      .then(response => resolve(true))
      .catch(error => reject(error));
    });
  };


  //
  // I. Draft and Batch Initialization code
  //

  // Given that all form values have been initialized, load them into form and trigger form display
  const initFormAndPresent = (accessioningTypeLocal, formValues) => {
    if (accessioningTypeLocal === ACCESSIONING_TYPE__OTHER_DNA) {
      setFormikOtherDNAInitialValues(prevState => {
        return {...prevState, ...formValues};
      });
    } else {
      setFormikEmbryonicInitialValues(prevState => {
        return {...prevState, ...formValues};
      });
    }
    setInitializationComplete(true);  // trigger form display
  };

  // Fetch and view/edit existing accessioning batch
  const LoadAccessioningBatch = () => {
    fetchAccessioning(editAccessioningId).then(loadedBatch => {
      if (loadedBatch) {
        setEditBatchData(loadedBatch);
        setAccessioningType(loadedBatch.accessioning_type);
        setClinic({id: loadedBatch.provider_id, company_name: loadedBatch.provider_company_name});
        // TODO setAccessioningEntryStatus

        const batchFiles = [];
        loadedBatch.files.forEach(file => {
          batchFiles.push({
            file_name: file.file_name,
            url: file.url,
            ext: file.ext,
            datetime: file.datetime
          });
        });
        setFilesUploaded(batchFiles);

        let initialValues = {};
        if (loadedBatch.accessioning_type === ACCESSIONING_TYPE__OTHER_DNA) {
          setFormikOtherDNAInitialValues(prevState => {
            initialValues = {
              ...prevState,
              test_type: loadedBatch.test_type,
              received_at: loadedBatch.received_at,
              patient_first_name: loadedBatch.patient_first_name,
              patient_last_name: loadedBatch.patient_last_name,
              patient_dob: loadedBatch.patient_dob,
              subject_role: loadedBatch.subject_role,
              related_to: loadedBatch.related_to,
              related_as: loadedBatch.related_as,
              buffer_lot: loadedBatch.buffer_lot,
              sample_type: loadedBatch.sample_type,
              accessioning_status: loadedBatch.accessioning_other_sample[0].accessioning_status,
              tube_id: loadedBatch.accessioning_other_sample[0].tube_id,
              QC: loadedBatch.accessioning_other_sample[0].QC ? 'accept' : 'reject',
              note: loadedBatch.note,
              gp_id: loadedBatch.accessioning_other_sample[0].gp_id,
            };
            return initialValues;
          });
        } else {
          /* Load embryonic accessioning batch for viewing/editing */
          setFormikEmbryonicInitialValues(prevState => {
            initialValues = {
              ...prevState,
              accessioning_status: loadedBatch.accessioning_status,
              received_at: loadedBatch.received_at,
              patient_first_name: loadedBatch.patient_first_name,
              patient_last_name: loadedBatch.patient_last_name,
              patient_dob: loadedBatch.patient_dob,
              buffer_lot: loadedBatch.buffer_lot,
              number_samples_received: loadedBatch.number_samples_received ? loadedBatch.number_samples_received.toString() : '',
              note: loadedBatch.note,
            };
            loadedBatch.embryo_samples.forEach(sample => {
              const s = newSampleLineObj();
              sample.QC = sample.QC ? 'accept' : 'reject';
              initialValues.embryo_samples.push({...s, ...sample});
            });
            return initialValues;
          });
        }
        // All form values initialized.  Trigger form display.
        setInitializationComplete(true);
      }
    }).catch((error) => {
      alertAndFail(`Unable to retrieve accessioning batch ${editAccessioningId} - ${error}`);
    })
  };

  //
  // Start here ...
  //

  useEffect(() => {
    fetchDraft().then(draft => {
      if (draft) {
        setDraftData(draft);
        // prior draft fetched.  What does user want to do with it?
        showModalDraftDialog().then(draftResponse => {
          if (draftResponse === 'QUIT') {
            onClose();

          } else if (draftResponse === 'DISCARD') {
            discardDraft(draft);
            if (creatingNewAccessioningBatch())
              setInitializationComplete(true);  // trigger form display
            else
              LoadAccessioningBatch();

          } else {  // User chose 'RESUME' draft
            const accessioningTypeLocal = 'accessioningType' in draft ? draft.accessioningType : ACCESSIONING_TYPE__CLINICAL;
            setAccessioningType(accessioningTypeLocal);
            if ('clinicId' in draft) setClinic({id: draft.clinicId, company_name: draft.clinicName});
            setFilesUploaded(draft.filesUploaded);

            if ('accessioningId' in draft && draft.accessioningId) {
              // User was editing this batch when draft saved
              fetchAccessioning(draft.accessioningId).then(loadedBatch => {
                if (loadedBatch) {
                  setEditBatchData(loadedBatch);
                  setEditAccessioningId(draft.accessioningId);
                  initFormAndPresent(accessioningTypeLocal, draft.formValues);
                }
              }).catch((error) => {
                alertAndFail(`Unable to retrieve accessioning batch ${draft.accessioningId} - ${error}`);
              })
            }

            initFormAndPresent(accessioningTypeLocal, draft.formValues);
          }
        })
      } else if (creatingNewAccessioningBatch())
        setInitializationComplete(true);  // trigger form display
      else
        LoadAccessioningBatch();
    }).catch((error) => {
      alertAndFail(`Unable to retrieve your last draft session - ${error}`);
      if (creatingNewAccessioningBatch())
        setInitializationComplete(true);  // trigger form display
      else
        LoadAccessioningBatch();
    })
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * discardDraft.  User has chosen to discard their previous work.  Remove draft record.
   * If user was creating new accessioning, also remove uploaded files.
   */
  const discardDraft = (draft) => {
    const token = getAuthToken();
    if (token) {
      // Delete uploaded files if draft was creating an accessioning, not editing an existing one
      if (!draft.accessioningId) {
        draft.filesUploaded.forEach((fileUploadedItem) => {
          deleteUploadedFile(getFileTypeFromFilename(fileUploadedItem.file_name), fileUploadedItem).then(() => {});
        });
      }

      deleteDraftWrapper();
    }
  }

  /**
   * Save current form data (clinic, values, files, etc.) to accessioning_batch_draft table for current user
   * so user can resume input the next time they access this screen.
   * Called whenever a file is uploaded/deleted or when user clicks Save Draft.
   * @param {object} values - Formik values
   * @param {object[]|null} newFilesUploaded - new value for filesUploaded.  Needed because saveDraft called
   *                                           within setState functions when files added/deleted.
   */
  const saveDraftWrapper = (values, newFilesUploaded = null) => {
    if (!saveDraftInProgress) {
      setSaveDraftInProgress(true);
      saveDraft({
        accessioningId: editAccessioningId,
        accessioningType: accessioningType,
        clinic_id: clinic ? clinic.id : null,
        formValues: values,
        filesUploaded: newFilesUploaded !== null ? [...newFilesUploaded] : [...filesUploaded]
      }).then(() => {
        setSaveDraftInProgress(false);
        setDisplayDraftSaved(true);
        setTimeout(() => setDisplayDraftSaved(false), 3000);
      }).catch((error) => {
        setSaveDraftInProgress(false);
        alertAndFail(`Unable to save your draft session - ${error}`);
      })
    }
  };

  /**
   * Delete draft from the back-end db
   * Called when draft discarded or batch successfully saved.
   */
  const deleteDraftWrapper = () => {
    deleteDraft().then(() => {
      // Do nothing
    }).catch((error) => {
      alertAndFail(`? Unable to delete your draft session ? - ${error} - please notify IT department`);
    })
  };

  /**
   * Present a RESUME/DISCARD/QUIT dialog to user when they have a prior session (saved draft).
   * @returns {Promise} - resolves to user's choice 'RESUME', 'DISCARD' or 'QUIT'
   */
  const showModalDraftDialog = () => {
    draftDialogRef.current.showModal();

    return new Promise((resolve) => {
      resolveDraftDialogRef.current = resolve;     // Store promise's resolve function
    });
  };

  /**
   * Component that presents user choice of RESUME, DISCARD or CANCEL when a prior draft is found.
   * @returns {JSX.Element|null}
   */
  const DraftUserChoice = () => (
    <>
      <div className="accessioning-choice-modal text-right" onClick={onClose}>x</div>
      <div className="mt-3 mb-2 text-center">
        You have a draft saved <b>{isoUTCtoLocalDateTime(draftData.datetime)}</b> for:
      </div>
      {'accessioningType' in draftData &&
        <div className="text-center">
          {`Type: ${accessioningTypeDisplayValues[draftData.accessioningType]}`}
        </div>
      }
      <div className="text-center">
        Clinic: {draftData.clinicName}
      </div>
      <div className="text-center">
        {'accessioningId' in draftData ? <div>{draftData.accessioningId}</div> : ''}
        {'accessioningType' in draftData && draftData.accessioningType === ACCESSIONING_TYPE__OTHER_DNA ? <span>Subject: </span> : <span>Patient: </span>}
        {draftData.patientFirstName} {draftData.patientLastName}
        {draftData.patientDOB ? ` b. ${displayDateMMDDYY(draftData.patientDOB)}` : 'Not Provided'}</div>
      <div className="text-center">
        {draftData.accessioningType !== ACCESSIONING_TYPE__OTHER_DNA ? displayCount(draftData.sampleCount, 'sample') + ' - ' : ''}
        {`${displayCount(draftData.filesUploaded.length, 'file')} uploaded`}
      </div>
      <div className='m-3 d-flex justify-content-center'>
        <table style={{border: 0, borderCollapse: 'separate', borderSpacing: '10px 25px'}}>
          <tbody>
          <tr className='accessioning-choice-modal' onClick={() => {
            draftDialogRef.current.close();
            if (resolveDraftDialogRef.current) resolveDraftDialogRef.current('RESUME');
          }}
          >
            <td style={{marginBottom: '15px'}}>
              <Button color="success" onClick={() => {
                draftDialogRef.current.close();
                if (resolveDraftDialogRef.current) resolveDraftDialogRef.current('RESUME');
              }}
              >
                RESUME
              </Button>
            </td>
            <td className='text-left' style={{marginBottom: '15px'}}>
              Load this draft and Resume working on it
            </td>
          </tr>
          <tr className='accessioning-choice-modal' onClick={() => {
            draftDialogRef.current.close();
            if (resolveDraftDialogRef.current) resolveDraftDialogRef.current('DISCARD');
          }}
          >
            <td style={{marginBottom: '15px'}}>
              <Button color="info" onClick={() => {
                draftDialogRef.current.close();
                if (resolveDraftDialogRef.current) resolveDraftDialogRef.current('DISCARD');
              }}
              >
                DISCARD
              </Button>
            </td>
            <td className='text-left' style={{marginBottom: '15px'}}>
              Discard this draft.<br/>Values, samples and all uploaded files removed.
            </td>
          </tr>
          <tr className='accessioning-choice-modal' onClick={() => {
            draftDialogRef.current.close();
            if (resolveDraftDialogRef.current) resolveDraftDialogRef.current('QUIT');
          }}
          >
            <td>
              <Button color="warning" className='mr-2' onClick={() => {
                draftDialogRef.current.close();
                if (resolveDraftDialogRef.current) resolveDraftDialogRef.current('QUIT');
              }}
              >
                QUIT
              </Button>
            </td>
            <td className='text-left'>
              Do nothing (exit)
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </>
  );


  //
  // II. File-Upload related code
  //

  /**
   * Add file(s) that user chose (with upload button or Drag&Drop) to the upload-files list, and upload them to S3
   * @param filesToUpload - array of files to add to upload-files list
   * @param values - Formik values object
   */
  const addUploadFiles = (filesToUpload, values) => {
    if (fileUploadsInProgress) return;

    setFileUploadsInProgress(true);
    filesToUpload.forEach((fileToUpload) => {
      if (filesUploaded.some((fileAlreadyUploaded) => fileAlreadyUploaded.name === fileToUpload.name)) {
        // Do nothing if file already uploaded
        // setFileUploadsInProgress(false);
      } else if (!acceptedFileTypeRegex.test(fileToUpload.name.split(".").pop())) {
        setFileUploadsInProgress(false);
        alertAndFail(`file(s) skipped - only ${acceptedFileExtensions.join(", ")} files allowed`);
      } else { // Upload the file
        // read file data
        const reader = new FileReader();

        reader.onloadend = () => {
          // file read successfully, upload it
          const token = getAuthToken();
          if (token) {
            axios(axiosParams('file/0', token, {
              file_name: fileToUpload.name,
              type: getFileTypeFromFilename(fileToUpload.name),
              file: reader.result,  // file contents
              accessioning: 'standalone'
            })).then((response) => {
              setFilesUploaded(curFilesUploaded => {
                const newFilesUploaded = [...curFilesUploaded, response.data.new_item];
                saveDraftWrapper(values, newFilesUploaded);
                return newFilesUploaded;
              });
              setFileUploadsInProgress(false);
            }).catch((error) => {
              setFileUploadsInProgress(false);
              alertAndFail(`Unable to upload ${fileToUpload.name} - ${error}`);
            })
          }
        };

        // Load file content.  Call reader.onloadend when done.
        reader.readAsDataURL(fileToUpload);
      }
    })
    setFileUploadsInProgress(false);
  }

  /**
   * Remove an uploaded file from S3 and upload-files array (after user hits delete (X) button or draft discarded)
   * @param {number} index - index of element to remove
   * @param values - Formik values object
   */
  const handleDeleteUploadedFile = (index, values) => {
    const fileUploadedItem = filesUploaded[index];
    deleteUploadedFile(getFileTypeFromFilename(fileUploadedItem.file_name), fileUploadedItem).then(() => {
      setFilesUploaded(curFilesUploaded => {
        const newFilesUploaded = [...curFilesUploaded];
        newFilesUploaded.splice(index, 1);
        saveDraftWrapper(values, newFilesUploaded);
        return newFilesUploaded;
      });
    }).catch((error) => {
      alertAndFail(`Unable to delete ${fileUploadedItem.file_name} - ${error}`);
    })
  };

  /**
   * Component where user can upload files via browse button or drag-and-drop area,
   * and displays the files currently uploaded, allowing user to view or delete them.
   * @param {Object} values - values of Formik form
   * @returns {JSX.Element}
   */
  const FileUploadSection = ({values}) => (
    <>
      <div><h5>SSF or Accessioning Photo Files</h5></div>
      {/* Display list of uploaded files */}
      {filesUploaded.length === 0 ? <div><i>No files uploaded</i></div> : ''}
      {filesUploaded.map((file, index) => (
        <div key={file.file_name} style={{fontSize: '80%', fontWeight: 'bold'}}>
          <span onClick={() => {viewS3File(file.url)}} style={{cursor: "pointer"}}>{file.file_name}</span>
          {/* Delete X Button */}
          <Button color="link" size="sm" onClick={event => {
            event.stopPropagation();
            handleDeleteUploadedFile(index, values);
          }}>
            <span className="text-danger ml-2" title="Remove this uploaded file">
              <FontAwesomeIcon icon="times"/>
            </span>
          </Button>
        </div>
      ))}
      {/*Dotted zone to drag/upload files*/}
      <div className='mt-2 w-75 rounded-lg justify-content-center align-items-center text-center'
           style={{border: '2px dotted gray', backgroundColor: '#e2e9ec', height: '12vh'}}
           onDragOver={(e) => e.preventDefault()}
           onDrop={(event) => {
             event.preventDefault();
             addUploadFiles(Array.from(event.dataTransfer.files), values);
           }}
      >
        <div className='mt-2'>Drag &amp; Drop SSF, photo or other relevant files here</div>
        <div>or</div>
        <Button color="primary" className='mt-1' onClick={() => hiddenInputFileButtonRef.current.click()}>Browse Files from your Computer</Button>
        <input
          type="file"
          id="hiddenInputFileButton"
          name="hiddenInputFileButton"
          multiple
          accept={acceptedFileTypesString}
          ref={hiddenInputFileButtonRef}
          className="hidden"
          onChange={(event) => {
            addUploadFiles(Array.from(event.target.files), values);
          }}
          onClick={(event) => {
            event.target.value = null;  // to allow selecting again
          }}
        />
      </div>
    </>
  );


  //
  // III. Submit, Save and Save/Matchup code
  //

  /**
   * User just clicked the SUBMIT button.  Save the batch if it's valid.  Include case match if applicable.
   * @param {Object} values - Formik field values
   * @param {function} setSubmitting - call with false to tell Formik when submission process is over (whether successful or canceled)
   */
  const handleSubmit = (values, setSubmitting) => {
    // non-Formik validation specific to accessioning type
    if (accessioningType === ACCESSIONING_TYPE__OTHER_DNA) {
      if (!additionalOtherDNAValidation(values)) {
        setSubmitting(false);
        return;
      }
    } else {
      if (!additionalEmbryonicValidation(values)) {
        setSubmitting(false);
        return;
      }
    }

    // If editing, starting point is loaded batch.
    // Embryo samples and files will be overwritten by form values.
    const saveBatchData = editingAccessioningBatch() ? {...editBatchData} : {};

    // Values common to all accessioning types
    saveBatchData.provider_id = clinic ? clinic.id : null;
    saveBatchData.patient_first_name = values.patient_first_name ? values.patient_first_name.trim() : '';
    saveBatchData.patient_last_name = values.patient_last_name ? values.patient_last_name.trim() : '';
    saveBatchData.patient_dob = values.patient_dob ? values.patient_dob : null;
    saveBatchData.received_at = values.received_at ? values.received_at : null;
    saveBatchData.buffer_lot = values.buffer_lot ? values.buffer_lot.trim() : null;
    if (accessioningType === ACCESSIONING_TYPE__CLINICAL)
      saveBatchData.accessioning_status = values.accessioning_status;
    else
      saveBatchData.accessioning_status = ACCESSIONING_STATUS__ACCESSIONED;
    if (saveBatchData.accessioning_status === ACCESSIONING_STATUS__RECEIVED) {
      saveBatchData.number_samples_received = values.number_samples_received && parseInt(values.number_samples_received) ? parseInt(values.number_samples_received) : null;
    } else {
      saveBatchData.number_samples_received = null;
    }
    if (accessioningType === ACCESSIONING_TYPE__OTHER_DNA || saveBatchData.accessioning_status === ACCESSIONING_STATUS__RECEIVED) {
      saveBatchData.note = values.note ? values.note.trim() : '';
    } else {
      saveBatchData.note = '';
    }

    if (creatingNewAccessioningBatch()) {
      saveBatchData.accessioning_type = accessioningType;
      saveBatchData.patient_id = null;  // no case match
      saveBatchData.live_flag = 1;  // 1 = live, 0 = draft, -1 = deleted
    }
    saveBatchData.files = [...filesUploaded];

    if (accessioningType === ACCESSIONING_TYPE__OTHER_DNA) {
      saveBatchData.test_type = values.test_type;
      saveBatchData.sample_type = values.sample_type;
      saveBatchData.subject_role = values.subject_role;
      if (values.subject_role !== ACCESSIONING_SUBJECT__PATIENT && values.subject_role !== ACCESSIONING_SUBJECT__PARTNER) {
        saveBatchData.related_to = values.related_to;
        saveBatchData.related_as = values.related_as;
      } else {
        saveBatchData.related_to = null;
        saveBatchData.related_as = null;
      }

      if (creatingNewAccessioningBatch()) saveBatchData.accessioning_other_sample = [{}];
      saveBatchData.accessioning_other_sample[0].tube_id = values.tube_id ? values.tube_id.trim() : '';
      saveBatchData.accessioning_other_sample[0].QC = values.QC === 'accept'; // TODO
      saveBatchData.accessioning_other_sample[0].gp_id = values.gp_id ? values.gp_id.trim() : '';
      saveBatchData.accessioning_other_sample[0].accessioning_status = 'accessioned'; // TODO - accessioningBatch also contains
    } else {
      // Values specific to embryonic accessioning types
      if (creatingNewAccessioningBatch()) {
        saveBatchData.sample_type = 'embryonic';
      }
      saveBatchData.embryo_samples = [];
      if (saveBatchData.accessioning_status === ACCESSIONING_STATUS__ACCESSIONED) {
        values.embryo_samples.forEach(embryo_sample => {
          const saveEmbryoSample = {
            ...embryo_sample,
            accessioning_status: 'accessioned', // TODO - don't overwrite accessioning_status when editing
            QC: (embryo_sample.QC === 'accept')
          };
          if (editingAccessioningBatch()) saveEmbryoSample.accessioning_batch_id = editAccessioningId;
          saveBatchData.embryo_samples.push(saveEmbryoSample);
        });
      }
    }

    const token = getAuthToken();
    if (!token) {
      setSubmitting(false);
      return;
    }

    // Look for a matching case if:
    //  - batch doesn't already have matching case
    //  - all required fields present: clinic, patient first and last name, patient dob
    //  - if embryonic, samples:
    //        - must be one or more viable ones
    //        - none can have "notprovided" for tissue_type
    //  - if other dna, tube_id is specified
    if ((creatingNewAccessioningBatch() || editBatchData.patient_id === null) &&
      saveBatchData.provider_id > 0 &&
      saveBatchData.patient_first_name && saveBatchData.patient_last_name && saveBatchData.patient_dob &&
      (accessioningType === ACCESSIONING_TYPE__OTHER_DNA ||
        !saveBatchData.embryo_samples.some(embryo_sample => embryo_sample.tissue_type === 'notprovided')) &&
      (accessioningType !== ACCESSIONING_TYPE__OTHER_DNA || saveBatchData.accessioning_other_sample[0].tube_id)
    ) {
      // Ask back-end to find a match
      axios(axiosParams('patients/test_accessioning_match', token, saveBatchData)).then(matchFoundResponse => {
        if (matchFoundResponse.data && matchFoundResponse.data.status !== 'none') {
          if (matchFoundResponse.data.status === 'error') {
            setSubmitting(false);
            alertAndFail('Match-up failed.  Please notify IT department.');
          } else if (matchFoundResponse.data.status === 'nomatch') {
            // No match was found.  Ask user what they want to do, then continue from there
            askLinkSave(matchFoundResponse, saveBatchData, token, setSubmitting);
          } else {
            // A match was found.  Ask user what they want to do, then continue from there
            askLinkSave(matchFoundResponse, saveBatchData, token, setSubmitting);
          }
        } else { // no matching case found - just save
          saveAccessioningBatch(token, saveBatchData, setSubmitting);
        }
      }).catch(error => {
        setSubmitting(false);
        alertAndFail('Match-up failed.  Please notify IT department.');
        console.error('match-up failed', error);
      })
    } else {
      // Don't look for matching case -- just save
      saveAccessioningBatch(token, saveBatchData, setSubmitting);
    }
  }


  /**
   * Additional validation (non-Formik) steps to take before saving embryonic accessioning batch.
   * If any step fails validation, display alert dialog
   * @param {object} values - formik form values
   * @returns {boolean} - true if valid for submission; false if not
   */
  const additionalEmbryonicValidation = (values) => {
    // clinic dropdown not within Formik, so have to verify separately
    if (accessioningType === ACCESSIONING_TYPE__CLINICAL || accessioningType === ACCESSIONING_TYPE__OTHER_DNA) {
      if (!clinic || clinic.id === null) return alertAndFail("Choosing a Clinic is Required");
    }

    if (values.accessioning_status === ACCESSIONING_STATUS__ACCESSIONED) {
      const sampleArray = values.embryo_samples;
      if (sampleArray.length === 0) return alertAndFail("? Batch contains no samples ?");

      // check for duplicate embryo numbers or tube labels
      const embryoNumberSet = new Set();
      const tubeLabelSet = new Set();
      for (let index = 0; index < sampleArray.length; index++) {
        const embryo_number = sampleArray[index].embryo_number;
        if (embryo_number !== 'NP') {
          if (embryoNumberSet.has(embryo_number))
            return alertAndFail(`Sample ${index + 1} duplicates Embryo # ${embryo_number}`);
          embryoNumberSet.add(embryo_number);
        }

        const tube_label = sampleArray[index].tube_label;
        if (tubeLabelSet.has(tube_label))
          return alertAndFail(`Sample ${index + 1} duplicates Tube Label ${tube_label}`);
        tubeLabelSet.add(tube_label);
      }
    }

    // passed all additional validations
    return true;
  }

  /**
   * Additional validation (non-Formik) before saving other/DNA accessioning batch.
   * If any step fails validation, display alert dialog
   * @param {object} values - formik form values
   * @returns {boolean} - true if valid for submission; false if not
   */
  const additionalOtherDNAValidation = (values) => {
    if (!values.sample_type) return alertAndFail("Sample Type is required");
    if (values.sample_type === 'saliva') {
      if (!values.buffer_lot) return alertAndFail("Buffer Lot is required for saliva samples");
    }

    // passed all additional validations
    return true;
  }

  const SavingOverlay = () => (
      <div className="loading-overlay">
        <div className="loading-content">
          <div className="spinner-border text-light" role="status">
            <span className="visually-hidden"></span>
          </div>
          <p>Saving... Please Wait</p>
        </div>
      </div>
  );

  /**
   * User has opted to link this new batch to the matching case during save.
   *
   * @param {number} patient_id - case (patient table) row id
   * @param {string} token
   * @param {Object} accessioningBatch - new AccessioningBatch object (Formik field values + ...)
   * @param setSubmitting - formik; is submission in progress
   */
  const linkAccessioningBatchToCaseAndSave = (patient_id, token, accessioningBatch, setSubmitting) => {
    linkAccessioningBatchToCase(accessioningBatch, patient_id, token).then(newAccessioningBatch => {
      saveAccessioningBatch(token, newAccessioningBatch, setSubmitting);
    }).catch(error => {
      alertAndFail('Submit failed.  Please notify IT department.');
      console.error('submit failed', error);
    });
  }

  /**
   * As part of askLinkSave, present a YES/NO/CANCEL dialog to user
   * @returns {Promise} - resolves to user's choice 'YES', 'NO' or 'CANCEL'
   */
  const showModalLinkDialog = () => {
    linkDialogRef.current.showModal();

    return new Promise((resolve) => {
      resolveLinkDialogRef.current = resolve;     // Store promise's resolve function
    });
  };

  /**
   * During submit, a matched case was found.
   * Ask user if they want to either:
   *    - link this Accessioning batch to it, (Yes)
   *    - just save the batch without linking the case, (No)
   *    - or just forget the whole thing (cancel)
   * and then act accordingly
   *
   * @param {Object} matchFound - response returned by get_..._accessioning_match
   * @param {Object} accessioningBatch - new AccessioningBatch object (Formik field values + ...)
   * @param token
   * @param setSubmitting
   */
  const askLinkSave = (matchFound, accessioningBatch, token, setSubmitting) => {
    if (matchFound.data.status === 'success') {
      setModalMatchMessage('');
      setModalDisplayMatchedCaseId(matchFound.data.matching_case.case_id ? matchFound.data.matching_case.case_id : `#${matchFound.data.matching_case.id}`);
      setModalMatchingCaseUrl(`../patients/${matchFound.data.matching_case.id}?provider=${accessioningBatch.provider_id}`);
    } else if (matchFound.data.status === 'error') {
      setModalMatchMessage(`ERROR: ${matchFound.data.message}`);
    } else
      setModalMatchMessage(matchFound.data.message);

    showModalLinkDialog().then(linkUserResponse => {
      if (linkUserResponse === 'LINKSAVE') {
        linkAccessioningBatchToCaseAndSave(matchFound.data.matching_case.id, token, accessioningBatch, setSubmitting)
      } else if (linkUserResponse === 'SAVE') {
        // just save the accessioning batch.  Leave the matching case alone.
        saveAccessioningBatch(token, accessioningBatch, setSubmitting);
      } else {  // linkUserResponse is 'CANCEL'.  Cancel submission.
        setSubmitting(false);
      }
    })
  };

  /**
   * Save new accessioning batch object to db (incl. data, samples, files)
   * @param token
   * @param {Object} accessioningBatch - new AccessioningBatch object
   * @param setSubmitting
   */
  const saveAccessioningBatch = (token, accessioningBatch, setSubmitting) => {
    let method;
    let url;
    if (creatingNewAccessioningBatch()) {
      method = 'POST';
      url = 'accessioning';
    }
    else if (editingAccessioningBatch()) {
      method = 'PUT';
      url = `accessioning/${accessioningBatch.id}`;
    }
    setShowSavingOverlay(true); // creates saving overlay

    axios(axiosParams(url, token, accessioningBatch, method)).then(() => {
      deleteDraftWrapper();
      onClose();
    })
    .catch(error => {
      alertAndFail('Submit failed.  Please notify IT department.');
      console.error('submit failed', error);
    })
    .finally(() => {
      setSubmitting(false); // tell Formik we're done with submission
      setShowSavingOverlay(false); // removes the loading overlay
    });
  }

  /**
   * Content of YES/NO/CANCEL dialog presented when user must choose whether to link new batch to matched case
   * @returns {JSX.Element}
   */
  const MatchLinkChoice = () => (
    <>
      {modalMatchMessage ?
        <div className='m-3'>
          No Match: {modalMatchMessage}<br />
          <br />
          Save this Accessioning ?<br />
        </div> :
        <div className='m-3'>
          Clinic and Patient match existing Case <a href={modalMatchingCaseUrl} target="_blank" title='Open case in new window'
                                              rel="noopener noreferrer">{modalDisplayMatchedCaseId}</a><br />
          Link Accessioning to this case when saving ?<br /><br />
        </div>
      }
      <Row className='mt-4 mr-0'>
        <Col md={4}>
          &nbsp;
        </Col>
        <Col md={4}>
          {modalMatchMessage === '' ? <Button autoFocus className='mr-2' onClick={() => {
            linkDialogRef.current.close();
            if (resolveLinkDialogRef.current) resolveLinkDialogRef.current('LINKSAVE');
           }}
          >
            YES
          </Button>
          : ''}
          <Button color="info" onClick={() => {
            linkDialogRef.current.close();
            if (resolveLinkDialogRef.current) resolveLinkDialogRef.current('SAVE');
          }}>
            {modalMatchMessage === '' ? 'NO' : 'YES'}
          </Button>
        </Col>
        <Col md={4} className='ml-auto'>
          <Button color="warning" className='mr-2' onClick={() => {
            linkDialogRef.current.close();
            if (resolveLinkDialogRef.current) resolveLinkDialogRef.current('CANCEL');
          }}>
            CANCEL
          </Button>
        </Col>
      </Row>
    </>
  );


  //
  // IV. Embryonic Form     (accessioning types: clinical, research, QC)
  //

  /**
   * Component to display title at top of Form when editing an existing Accessioning Batch
   * If linked to case, display case GPCL ID with hyperlink to case
   * @returns {JSX.Element}
   */
  const EditingBanner = () => {
    if (editBatchData.patient_id) {
      return (
        <span>
          {`Editing ${accessioningTypeDisplayValues[accessioningType]} ${editAccessioningId} - Case `}
          <CaseLink providerId={editBatchData.provider_id} patientId={editBatchData.patient_id}
                    caseId={editBatchData.case_id}/>
        </span>
      );
    }
    return <span>{`Editing ${accessioningTypeDisplayValues[accessioningType]} ${editAccessioningId}`}</span>;
  };

  // Display list of issues in issue column for embryo sample row
  const DisplayIssues = ({issues}) => {
    return issues.split('|').map(issue => <div key={issue}>- {accessioning_sample_issues[issue]}</div>);
  }

  /**
   * Open Sample Issues dialog when user clicks sample row's Issue column
   * @param {array} valuesEmbryoSamples - Formik's values.embryo_samples
   * @param {number} embryoSampleIndex - index of sample row to display/edit issues for
   * @param setFieldValue - Formik's setFieldValue for embryonic form
   */
  const openSampleIssueDialog = (valuesEmbryoSamples, embryoSampleIndex, setFieldValue) => {
    setIssueSelectionParams({
      valuesEmbryoSamples,  // used by dialog
      embryoSampleIndex,    // used by dialog
      callbackFunction: handleSampleIssueDialogReturn,
      setFieldValue,        // stashed for use by handleSampleIssueDialogReturn
    });
    issueDialogRef.current.showModal();
  };

  // function passed to EmbryoIssueSelection dialog for it to obtain its parameters
  const getIssueSelectionParams = () => issueSelectionParams;

  // Callback invoked by EmbryoIssueSelection to close dialog and clear parameters
  const handleSampleIssueDialogReturn = () => {
    issueDialogRef.current.close();
    setIssueSelectionParams(null);
  };

  const EmbryonicForm = ({handleBlur, values, setFieldValue, isSubmitting}) => {
    // Inject custom blur handler remove leading/trailing blanks.  Use only for freeform strings.
    const handleBlurTrim = (e) => {
      const {name, value} = e.target;
      setFieldValue(name, value.trim(), false);  // Update Formik state with trimmed value
      handleBlur(e); // invoke default Formik blur handler to preserve validation, touched, etc.
    };

    return (
      <>
      <Form autoComplete="off" style={{backgroundColor: accessioningTypeColorBg[accessioningType]}}>
        {/* Issue checkbox dialog displayed when user clicks issue field within sample row */}
        <Row style={{fontSize: '150%'}}>
          <Col md={{size: 7, offset: 1}} className='p-1'>
            {editingAccessioningBatch() ? EditingBanner() : `Creating ${accessioningTypeDisplayValues[accessioningType]}`}
          </Col>
        </Row>
        <Row className="mt-2">
          {/*
              Clinic search/select dropdown
            */}
          <Col md={{size: 7, offset: 1}} className='p-1'>
            <div>Clinic</div>
            <div style={{border: "1px solid black", padding: "3px", backgroundColor: "white", cursor: "pointer"}}
                 onClick={() => clinicDialogRef.current.showModal()}>
              {clinic && clinic.id && clinic.company_name ? clinic.company_name : <span><i>Not Provided</i></span>}
            </div>
          </Col>
          {/*
              Sample Received Date
            */}
          <Col md={3} className='p-1'>
            <div>Sample Received</div>
            <div><Field name="received_at" type="date" validate={validateRequiredRecentPastDate}/></div>
            <ErrorMessage name="received_at">
              {msg => <div className='text-danger'>{msg}</div>}
            </ErrorMessage>
          </Col>
        </Row>
        <Row className="mt-2">
          {/*
              Patient name, DOB
            */}
          <Col md={{size: 7, offset: 1}} className='p-1'>
            <Row className='w-100'>
              <Col md={6}>
                <div>Patient</div>
                <div>
                  <Field name="patient_first_name" type="text" className='w-100'
                         onBlur={handleBlurTrim}
                         validate={accessioningType === ACCESSIONING_TYPE__CLINICAL ? validateRequiredString : null}/>
                </div>
                <small>first name</small>
                <ErrorMessage name="patient_first_name">
                  {msg => <div className='text-danger'>{msg}</div>}
                </ErrorMessage>
              </Col>
              <Col md={6}>
                <div>&nbsp;</div>
                <div>
                  <Field name="patient_last_name" type="text" className='w-100'
                         onBlur={handleBlurTrim}
                         validate={accessioningType === ACCESSIONING_TYPE__CLINICAL ? validateRequiredString : null}/>
                </div>
                <small>last name</small>
                <ErrorMessage name="patient_last_name">
                  {msg => <div className='text-danger'>{msg}</div>}
                </ErrorMessage>
              </Col>
            </Row>
          </Col>
          <Col md={3} className='p-1'>
            <div>DOB <ShowChildren when={!values.patient_dob}><span style={{fontSize:"75%"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i>not provided</i></span></ShowChildren></div>
            <div>
              <Field name="patient_dob" type="date"/>
              <span className="smallWordButton" style={{color: "blue", marginLeft: '3px'}} onClick={()=>{
                  setFieldValue('patient_dob', null);
                  const dateInput = document.querySelector('input[name="patient_dob"]');
                  if (dateInput) dateInput.value = '';
              }}>clear</span>
            </div>
            <ErrorMessage name="patient_dob">
              {msg => <div className='text-danger'>{msg}</div>}
            </ErrorMessage>
          </Col>
        </Row>
        <Row className="mt-2">
          {/* Buffer Lot */}
          <Col md={{size: 3, offset: 1}} className='p-1'>
            <div className="mt-1">Buffer Lot</div>
            <div><Field name="buffer_lot" onBlur={handleBlurTrim} /></div>
            <ErrorMessage name="buffer_lot">
              {msg => <div className='text-danger'>{msg}</div>}
            </ErrorMessage>
          </Col>
          {/* Accession/Received */}
          <ShowChildren when={accessioningType === ACCESSIONING_TYPE__CLINICAL && (!values.embryo_samples || values.embryo_samples.length === 0)}>
            <Col md={4} className='p-1'>
              <div className="mt-1">Status</div>
              <div role="group" aria-labelledby="radio-group" className="mt-1">
                <label>
                  <Field type="radio" name="accessioning_status" value={ACCESSIONING_STATUS__ACCESSIONED}
                         checked={values.accessioning_status === ACCESSIONING_STATUS__ACCESSIONED}
                  />
                  Accessioned
                </label>
                &nbsp;&nbsp;&nbsp;
                <label>
                  <Field type="radio" name="accessioning_status" value={ACCESSIONING_STATUS__RECEIVED}
                         checked={values.accessioning_status === ACCESSIONING_STATUS__RECEIVED}
                  />
                  Received
                </label>
              </div>
            </Col>
          </ShowChildren>
        </Row>
        <ShowChildren when={accessioningType === ACCESSIONING_TYPE__CLINICAL && values.accessioning_status === ACCESSIONING_STATUS__RECEIVED}>
          <Row>
            <Col md={{size: 3, offset: 1}} className='p-1'>
              <div className="mt-1">Number of Samples</div>
              <div>
                <Field type="text" name="number_samples_received"
                  validate={validateRequiredString}
                  onChange={(e) => {
                    if (e.target.value < "1" || isNaN(Number(e.target.value)))
                      setFieldValue("number_samples_received", "");
                    else if (Number(e.target.value) > "99")
                      setFieldValue("number_samples_received", "99");
                    else
                      setFieldValue("number_samples_received", e.target.value);
                  }}
                /><
              /div>
              <ErrorMessage name="number_samples_received">
                {msg => <div className='text-danger'>{msg}</div>}
              </ErrorMessage>
            </Col>
            <Col md={5} className='p-1'>
              <div className="mt-1">Note</div>
              <Field name="note">
                {({ field }) => (
                  <TextareaAutosize
                    {...field}
                    className="w-100"
                    style={{ minHeight: '1rem' }} // Use minHeight instead of height
                    type="text"
                    onBlur={handleBlurTrim}
                  />
                )}
              </Field>
            </Col>
          </Row>
        </ShowChildren>
        {/*
            File Upload Zone
        */}
        <Row className="mt-2 w-100">
          <Col md={{size: 12, offset: 1}} className='p-1'>
            <FileUploadSection values={values}/>
          </Col>
        </Row>
        <Row>
          <hr className="w-100"/>
        </Row>
        <ShowChildren when={values.accessioning_status === ACCESSIONING_STATUS__ACCESSIONED}>
          {/*
              Embryo Samples Table
          */}
          <FieldArray name="embryo_samples">
            {({push, remove}) => (
              <>
                <Row className="mt-2">
                  <Col md={{size: 1, offset: 1}} className='p-1'>
                    <h5>Samples</h5>
                  </Col>
                  <Col>
                    <Button color="primary" size='sm' className='ml-2'
                            onClick={() => push(newSampleLineObj())}
                    >
                      [+]
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col md={{size: 10, offset: 1}} className='p-1'>
                    <table className='w-100 mb-3 table-bordered'>
                      <thead>
                      <tr>
                        <th style={{width: '2%'}}>&nbsp;</th>
                        <th style={{width: '12%'}}>Tube Label</th>
                        <th style={{width: '3%'}}>Embryo #</th>
                        <th style={{width: '13%'}}>Sample Type</th>
                        <th style={{width: '28%'}}>Issues</th>
                        <th style={{width: '7%'}}>QC</th>
                        <th style={{width: '4%'}}>Hold</th>
                        <th style={{width: '30%'}}>Note</th>
                        <th>&nbsp;</th>
                      </tr>
                      </thead>
                      <tbody>
                      {values.embryo_samples && values.embryo_samples.length > 0 && values.embryo_samples.map((sample, index) => (
                        <tr key={index} style={{backgroundColor: 'white'}}>
                          <td className='text-center'>{index + 1}</td>
                          <td>
                            <Field
                              name={`embryo_samples[${index}].tube_label`}
                              className='border-0 w-100 text-center'
                              onBlur={handleBlurTrim}
                              validate={validateRequiredString}
                            />
                            <ErrorMessage name={`embryo_samples[${index}].tube_label`}>
                              {msg => <div className='text-danger'>{msg}</div>}
                            </ErrorMessage>
                          </td>
                          <td>
                            <Field
                              name={`embryo_samples[${index}].embryo_number`}
                              className='border-0 w-100 text-center'
                              onBlur={handleBlurTrim}
                              validate={validateEmbryoNumber}
                            />
                            <ErrorMessage name={`embryo_samples[${index}].embryo_number`}>
                              {msg => <div className='text-danger'>{msg}</div>}
                            </ErrorMessage>
                          </td>
                          <td>
                            <Field
                              name={`embryo_samples[${index}].tissue_type`}
                              component="select"
                              className='border-0 w-100'
                              validate={validateRequiredString}
                            >
                              <option value=""></option>
                              <option value="trophectoderm">Trophectoderm</option>
                              <option value="arrested">Whole Embryo</option>
                              <option value="notprovided">Not Provided</option>
                              <option value="nc">NC</option>
                              <option value="bg">BG</option>
                            </Field>
                            <ErrorMessage name={`embryo_samples[${index}].tissue_type`}>
                              {msg => <div className='text-danger'>{msg}</div>}
                            </ErrorMessage>
                          </td>
                          <td style={{fontSize: '80%', cursor: 'pointer'}}
                              onClick={() => openSampleIssueDialog(values.embryo_samples, index, setFieldValue)}
                              title="click to edit"
                          >
                            {values.embryo_samples[index].issue ? <DisplayIssues issues={values.embryo_samples[index].issue} />
                                  : <div style={{color:'gray', fontStyle:'italic', textAlign:"center"}}>none</div>}
                          </td>
                          <td>
                            <Field
                              name={`embryo_samples[${index}].QC`}
                              component="select"
                              className='border-0 w-100'
                            >
                              <option value="accept">Accept</option>
                              <option value="reject">Reject</option>
                            </Field>
                            <ErrorMessage name={`embryo_samples[${index}].QC`}>
                              {msg => <div className='text-danger'>{msg}</div>}
                            </ErrorMessage>
                          </td>
                          <td className='text-center'>
                            <Field
                              name={`embryo_samples[${index}].on_hold`}
                              type="checkbox"
                              className='border-0'
                            />
                          </td>
                          <td>
                            <Field name={`embryo_samples[${index}].note`}>
                              {({ field }) => (
                                <TextareaAutosize
                                  {...field}
                                  className="border-0 w-100"
                                  style={{ minHeight: '1rem' }} // Use minHeight instead of height
                                  type="text"
                                  onBlur={handleBlurTrim}
                                />
                              )}
                            </Field>
                            <ErrorMessage name={`embryo_samples[${index}].note`}>
                              {msg => <div className='text-danger'>{msg}</div>}
                            </ErrorMessage>
                          </td>
                          <td onClick={() => remove(index)}>
                          <span className="text-danger px-2" title="Delete this sample">
                            <FontAwesomeIcon icon="times"/>
                          </span>
                          </td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </>
            )}
          </FieldArray>
        </ShowChildren>
        {/* SAVE DRAFT, SUBMIT, CANCEL buttons */}
        <Row>
          <Col md={4}>
            &nbsp;
          </Col>
          <Col md={4} className="d-flex align-items-center">
            <Button color="info" onClick={() => saveDraftWrapper(values)} disabled={saveDraftInProgress}>
              Save Draft</Button>
            {displayDraftSaved && <span className="ml-2 text-success strong">Draft Saved</span>}
          </Col>
          <Col md={4} className='ml-auto'>
            <Button color="success" className='mr-3' type="submit" disabled={isSubmitting}>Submit</Button>
            <Button color="warning" className='mr-3' onClick={onClose}>Cancel</Button>
          </Col>
        </Row>
      </Form>
      </>
    );
  }

  //
  // V. Other DNA Form       (accessioning type: Other DNA)
  //

  const OtherDNAForm = ({handleBlur, values, setFieldValue, isSubmitting}) => {
    // Inject custom blur handler remove leading/trailing blanks.  Use only for freeform strings.
    const handleBlurTrim = (e) => {
      const {name, value} = e.target;
      setFieldValue(name, value.trim(), false);  // Update Formik state with trimmed value
      handleBlur(e); // invoke default Formik blur handler to preserve validation, touched, etc.
    };

    return (
      <Form autoComplete="off" style={{backgroundColor: accessioningTypeColorBg[accessioningType]}}>
        <Row style={{fontSize: '150%'}}>
          <Col md={{size: 7, offset: 1}} className='p-1'>
            {editingAccessioningBatch() ? `Editing ${accessioningTypeDisplayValues[accessioningType]} ${editAccessioningId}` : `Creating ${accessioningTypeDisplayValues[accessioningType]}`}
          </Col>
        </Row>
        <Row className="mt-2">
          {/*
              Clinic search/select dropdown
            */}
          <Col md={{size: 7, offset: 1}} style={{paddingTop: '1.5rem', paddingLeft: '.25rem'}}>
            <div>Clinic</div>
            <div style={{border: "1px solid black", padding: "3px", backgroundColor: "white", cursor: "pointer"}}
                 onClick={() => clinicDialogRef.current.showModal()}>
              {clinic && clinic.id ? clinic.company_name : <span><i>Not Provided</i></span>}
            </div>
          </Col>
          {/*
              Test Type
            */}
          <Col md={3} className='p-1'>
            <div className='p-1'>Test Type</div>
            <div role="group" aria-labelledby="radio-group">
              <label>
                <Field type="radio" name="test_type" value="m2" checked={values.test_type === 'm2'}/>&nbsp;
                M2
              </label><br/>
              <label>
                <Field type="radio" name="test_type" value="pgt" checked={values.test_type === 'pgt'}/>&nbsp;
                PGT
              </label><br/>
              <label>
                <Field type="radio" name="test_type" value="notprovided"
                       checked={values.test_type === 'notprovided'}/>&nbsp;
                Not provided
              </label>
            </div>
            <ErrorMessage name="test_type">
              {msg => <div className='text-danger'>{msg}</div>}
            </ErrorMessage>
          </Col>
        </Row>
        <Row className="mt-2">
          {/*
              Subject name, DOB
            */}
          <Col md={{size: 6, offset: 1}} className='p-1'>
            <Row className='w-100'>
              <Col md={6} style={{paddingTop: '2rem'}}>
                <div>Subject</div>
                <div>
                  <Field name="patient_first_name" type="text" className='w-100' onBlur={handleBlurTrim} />
                </div>
                <small>first name</small>
                <ErrorMessage name="patient_first_name">
                  {msg => <div className='text-danger'>{msg}</div>}
                </ErrorMessage>
              </Col>
              <Col md={6} style={{paddingTop: '2rem'}}>
                <div>&nbsp;</div>
                <div>
                  <Field name="patient_last_name" type="text" className='w-100' onBlur={handleBlurTrim} />
                </div>
                <small>last name</small>
                <ErrorMessage name="patient_last_name">
                  {msg => <div className='text-danger'>{msg}</div>}
                </ErrorMessage>
              </Col>
            </Row>
          </Col>
          <Col md={2} style={{paddingTop: '2.25rem', paddingLeft: '.25rem', paddingRight: '.25rem'}}>
            <div>DOB</div>
            <div>
              <Field name="patient_dob" type="date"/>
              <span className="smallWordButton" style={{color: "blue", marginLeft: '3px'}} onClick={()=>{setFieldValue('patient_dob', undefined);}}>Not Provided</span>
            </div>
            <ErrorMessage name="patient_dob">
              {msg => <div className='text-danger'>{msg}</div>}
            </ErrorMessage>
          </Col>
          {/*
              Subject is which?  patient, partner, relative ...
          */}
          <Col md={2} className='p-1'>
            <div className='p-1'>Subject is</div>
            <div role="group" aria-labelledby="radio-group">
              <label>
                <Field type="radio" name="subject_role" value={ACCESSIONING_SUBJECT__PATIENT}
                       checked={values.subject_role === ACCESSIONING_SUBJECT__PATIENT}/>&nbsp;
                Patient
              </label><br/>
              <label>
                <Field type="radio" name="subject_role" value={ACCESSIONING_SUBJECT__PARTNER}
                       checked={values.subject_role === ACCESSIONING_SUBJECT__PARTNER}/>&nbsp;
                Partner
              </label><br/>
              <label>
                <Field type="radio" name="subject_role" value={ACCESSIONING_SUBJECT__RELATIVE}
                       checked={values.subject_role === ACCESSIONING_SUBJECT__RELATIVE}/>&nbsp;
                Relative
              </label><br/>
              <label>
                <Field type="radio" name="subject_role" value={ACCESSIONING_SUBJECT__DONOR}
                       checked={values.subject_role === ACCESSIONING_SUBJECT__DONOR}/>&nbsp;
                Donor
              </label><br/>
              <label>
                <Field type="radio" name="subject_role" value={ACCESSIONING_SUBJECT__UNKNOWN}
                       checked={values.subject_role === ACCESSIONING_SUBJECT__UNKNOWN}/>&nbsp;
                Unknown
              </label>
            </div>
            <ErrorMessage name="subject_role">
              {msg => <div className='text-danger'>{msg}</div>}
            </ErrorMessage>
          </Col>
        </Row>
        {/*
          If subject is relative, which one?  (daughter, mother, son, father ...)  and to whom? (patient, partner)
        */}
        {values.subject_role === 'relative' && (
          <Row className="mt-2">
            <Col md={{size: 3, offset: 1}} className='p-1'>
              <div className='p-1'>Related to</div>
              <div role="group" aria-labelledby="radio-group">
                <label>
                  <Field type="radio" name="related_to" value="patient"
                         checked={values.related_to === 'patient'}/>&nbsp;
                  Patient
                </label><br/>
                <label>
                  <Field type="radio" name="related_to" value="partner"
                         checked={values.related_to === 'partner'}/>&nbsp;
                  Partner
                </label>
              </div>
              <ErrorMessage name="related_to">
                {msg => <div className='text-danger'>{msg}</div>}
              </ErrorMessage>
            </Col>
            <Col md={2} className='p-1'>
              <div className='p-1'>Relationship</div>
              <div role="group" aria-labelledby="radio-group">
                <label>
                  <Field type="radio" name="related_as" value={ACCESSIONING_RELATIONSHIP__DAUGHTER}
                         checked={values.related_as === ACCESSIONING_RELATIONSHIP__DAUGHTER}/>&nbsp;
                  Daughter
                </label><br/>
                <label>
                  <Field type="radio" name="related_as" value={ACCESSIONING_RELATIONSHIP__SON}
                         checked={values.related_as === ACCESSIONING_RELATIONSHIP__SON}/>&nbsp;
                  Son
                </label><br/>
                <label>
                  <Field type="radio" name="related_as" value={ACCESSIONING_RELATIONSHIP__MOTHER}
                         checked={values.related_as === ACCESSIONING_RELATIONSHIP__MOTHER}/>&nbsp;
                  Mother
                </label><br/>
                <label>
                  <Field type="radio" name="related_as" value={ACCESSIONING_RELATIONSHIP__FATHER}
                         checked={values.related_as === ACCESSIONING_RELATIONSHIP__FATHER}/>&nbsp;
                  Father
                </label>
              </div>
            </Col>
            <Col md={2} className='p-1'>
              <div className='p-1'>&nbsp;</div>
              <div role="group" aria-labelledby="radio-group">
                <label>
                  <Field type="radio" name="related_as" value={ACCESSIONING_RELATIONSHIP__AUNT}
                         checked={values.related_as === ACCESSIONING_RELATIONSHIP__AUNT}/>&nbsp;
                  Aunt
                </label><br/>
                <label>
                  <Field type="radio" name="related_as" value={ACCESSIONING_RELATIONSHIP__UNCLE}
                         checked={values.related_as === ACCESSIONING_RELATIONSHIP__UNCLE}/>&nbsp;
                  Uncle
                </label><br/>
                <label>
                  <Field type="radio" name="related_as" value={ACCESSIONING_RELATIONSHIP__SISTER}
                         checked={values.related_as === ACCESSIONING_RELATIONSHIP__SISTER}/>&nbsp;
                  Sister
                </label><br/>
                <label>
                  <Field type="radio" name="related_as" value={ACCESSIONING_RELATIONSHIP__BROTHER}
                         checked={values.related_as === ACCESSIONING_RELATIONSHIP__BROTHER}/>&nbsp;
                  Brother
                </label>
              </div>
            </Col>
            <Col md={2} className='p-1'>
              <div className='p-1'>&nbsp;</div>
              <div role="group" aria-labelledby="radio-group">
                <label>
                  <Field type="radio" name="related_as" value={ACCESSIONING_RELATIONSHIP__COUSIN_FEMALE}
                         checked={values.related_as === ACCESSIONING_RELATIONSHIP__COUSIN_FEMALE}/>&nbsp;
                  Cousin (female)
                </label><br/>
                <label>
                  <Field type="radio" name="related_as" value={ACCESSIONING_RELATIONSHIP__COUSIN_MALE}
                         checked={values.related_as === ACCESSIONING_RELATIONSHIP__COUSIN_MALE}/>&nbsp;
                  Cousin (male)
                </label>
              </div>
              <ErrorMessage name="related_as">
                {msg => <div className='text-danger'>{msg}</div>}
              </ErrorMessage>
            </Col>
          </Row>
        )}
        <Row className="mt-2">
          {/* Received date */}
          <Col md={{size: 2, offset: 1}} className='p-1'>
            <div>Sample Received</div>
            <div><Field name="received_at" type="date" validate={validateRequiredRecentPastDate}/></div>
            <ErrorMessage name="received_at">
              {msg => <div className='text-danger'>{msg}</div>}
            </ErrorMessage>
          </Col>
          {/* Sample Type */}
          <Col md={2} className='p-1'>
            <div>Sample Type</div>
            <Field
              name="sample_type"
              component="select"
            >
              <option value=""></option>
              <option value="saliva">Saliva</option>
              <option value="sperm">Sperm</option>
              <option value="dna">Extracted DNA</option>
              <option value="other">other</option>
            </Field>
          </Col>
          {/* Tube ID */}
          <Col md={2} className='p-1'>
            <div>Tube ID</div>
            <div><Field name="tube_id" onBlur={handleBlurTrim} /></div>
            <ErrorMessage name="tube_id">
              {msg => <div className='text-danger'>{msg}</div>}
            </ErrorMessage>
          </Col>
          {/* GP ID */}
          <Col md={2} className='p-1'>
            <div>GP ID</div>
            <Field name="gp_id" onBlur={handleBlurTrim} />
            <ErrorMessage name="gp_id">
              {msg => <div className='text-danger'>{msg}</div>}
            </ErrorMessage>
          </Col>
        </Row>
        <Row className="mt-2">
          {/* Buffer Lot */}
          <Col md={{size: 3, offset: 1}} className='p-1'>
            <div className="mt-1">Buffer Lot</div>
            <div><Field name="buffer_lot" onBlur={handleBlurTrim} /></div>
            <ErrorMessage name="buffer_lot">
              {msg => <div className='text-danger'>{msg}</div>}
            </ErrorMessage>
          </Col>
          {/* QC */}
          <Col md={2} className='p-1'>
            <div>QC</div>
            <div role="group" aria-labelledby="radio-group">
              <label>
                <Field type="radio" name="QC" value="accept" checked={values.QC === 'accept'} />&nbsp;
                Accept
              </label><br/>
              <label>
                <Field type="radio" name="QC" value="reject" checked={values.QC === 'reject'} />&nbsp;
                Reject
              </label>
            </div>
            <ErrorMessage name="QC">
              {msg => <div className='text-danger'>{msg}</div>}
            </ErrorMessage>
          </Col>
          {/* Note */}
          <Col md={5} className='p-1'>
            <div className="mt-1">Note</div>
            <Field name="note">
              {({ field }) => (
                <TextareaAutosize
                  {...field}
                  className="w-100"
                  style={{ minHeight: '1rem' }} // Use minHeight instead of height
                  type="text"
                  onBlur={handleBlurTrim}
                />
              )}
            </Field>
          </Col>
        </Row>
        {/* File Upload Zone */}
        <Row className="mt-2 mb-2 w-100">
          <Col md={{size: 12, offset: 1}} className='p-1'>
            <FileUploadSection values={values}/>
          </Col>
        </Row>
        {/* SAVE DRAFT, SUBMIT, CANCEL buttons */}
        <Row>
          <Col md={4}>
            &nbsp;
          </Col>
          <Col md={4} className="d-flex align-items-center">
            <Button color="info" onClick={() => saveDraftWrapper(values)} disabled={saveDraftInProgress}>
              Save Draft</Button>
            {displayDraftSaved && <span className="ml-2 text-success strong">Draft Saved</span>}
          </Col>
          <Col md={4} className='ml-auto'>
            <Button color="success" className='mr-3' type="submit" disabled={isSubmitting}>Submit</Button>
            <Button color="warning" className='mr-3' onClick={onClose}>Cancel</Button>
          </Col>
        </Row>
      </Form>
    );
  }


  //
  // VI. Render
  //

  return (
    <>
      {/* Error Alert Modal */}
      <Dialog title="Alert" isOpen={showErrorAlertModal} className='accessioning-error-alert-modal'
            onClose={()=>setShowErrorAlertModal(false)}>
        <div className='m-3'>{modalAlertMessage}</div>
      </Dialog>

      {/* Draft Choice dialog -- User can Resume (load draft), Discard (delete draft) or Quit */}
      <dialog ref={draftDialogRef} className='accessioning-success-alert-modal' style={{width: '640px'}}>
        {draftData && <DraftUserChoice />}
      </dialog>

      {/* Match-Case Link Dialog - link new batch to case-match found?  User opts for YES/NO/CANCEL */}
      <dialog ref={linkDialogRef} className='accessioning-success-alert-modal' style={{width:'640px'}}>
        <MatchLinkChoice />
      </dialog>

      {/* Clinic selector dialog displayed when user clicks clinic dropdown */}
      <dialog ref={clinicDialogRef} style={{width:'640px'}}>
        <ClinicDropdown setClinic={setClinic} clinicDialogRef={clinicDialogRef} />
      </dialog>

      {/*Issue Selector dialog displayed when user clicks '...' button in embryonic sample row issue column*/}
      <dialog ref={issueDialogRef} style={{width:'640px'}}>
        <EmbryoIssueSelection getIssueSelectionParams={getIssueSelectionParams} />
      </dialog>

      {showSavingOverlay && <SavingOverlay />}

      {/* Display form according to accessioning type */}
      {initializationComplete ? (
        <Formik initialValues={accessioningType === ACCESSIONING_TYPE__OTHER_DNA ? formikOtherDNAInitialValues : formikEmbryonicInitialValues}
          onSubmit={(values, {setSubmitting}) => handleSubmit(values, setSubmitting)}
        >
          {
            ({handleBlur, values, setFieldValue, isSubmitting}) => {
              return (accessioningType === ACCESSIONING_TYPE__OTHER_DNA ?
                <OtherDNAForm values={values} isSubmitting={isSubmitting} handleBlur={handleBlur} setFieldValue={setFieldValue} /> :
                <EmbryonicForm values={values} isSubmitting={isSubmitting} handleBlur={handleBlur} setFieldValue={setFieldValue} />)
            }
          }
        </Formik>
      ) : (
        <div className='mt-3 mb-2 ml-1'>Waiting for Draft choice...</div>
      )}
    </>
  );
};
