// General utility constants and functions for Accessioning

import React from "react";
import axios from "axios";

export const TYPE__CLINIC_SSF = 'samples_form'; // report-type/field in Patient table/form - 'Sample Submission Forms'
export const TYPE__GP_INTERNAL_FILES = 'gp_files'; // report-type/field in Patient table/form - 'GP Internal Documents'

// Acceptable extensions for file uploads
export const acceptedFileExtensions = ["pdf","png","jpg","jpeg","xls","xlsx","txt","rtf","doc","docx"];
export const acceptedFileTypeRegex = new RegExp(acceptedFileExtensions.join("|"), "i");
export const acceptedFileTypesString = acceptedFileExtensions.map((ext) => `.${ext}`).join(",") +
                                          ",application/vnd.openxmlformats-officedocument.wordprocessingml.document"

/**
 * Determine CP type of file from its name
 */
export const getFileTypeFromFilename = (filename) => {
  // assume PDFs are submission forms, and anything else is an internal-only image or other doc.
  if (filename.split(".").pop().toLowerCase() === 'pdf') return TYPE__CLINIC_SSF;
  return TYPE__GP_INTERNAL_FILES;
}

/**
 * Based on count, e.g., display "no things", "1 thing", "3 things"
 * @param count
 * @param thing
 * @returns {string}
 */
export const displayCount = (count, thing) => {
  if (count === undefined || count === null || count === 0) return `no ${thing}s`;
  if (count === 1) return `1 ${thing}`;
  return `${count} ${thing}s`;
}


// YYYY-MM-DD to mm/dd/yy
/**
 * Format YYYY-MM-DD or iso datetime for display
 * @param dateString - string in form YYYY-MM-DD or iso YYYY-MM-DDTHH:MM:SS UTC time
 * @returns {string} - date in m/d/yy format or m/d/yy h:m ampm in user local time
 */
export const displayDateMMDDYY = dateString => {
  if (!dateString) return "";
  if (dateString.indexOf('T') > -1) {
    const isoString = new Date(dateString);
    return isoString.toLocaleDateString('en-US', {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }).replace(/,/g, '').toLowerCase();
  }
  const [year, month, day] = dateString.split("-");
  return `${parseInt(month, 10)}/${parseInt(day,10)}/${year.substr(2)}`;
};


// iso YYYY-MM-DD HH:MM:SS to user's local date time
export const isoUTCtoLocalDateTime = isoString => {
  const date = new Date(isoString);

  const options = {
    weekday: 'long',   // Day of the week
    year: 'numeric',   // Full year
    month: 'long',     // Full month name
    day: 'numeric',    // Day of the month
    hour: 'numeric',   // Hours
    minute: 'numeric', // Minutes
    hour12: true       // Use 12-hour AM/PM format
  };

  return date.toLocaleDateString(undefined, options);
};


/**
 * Return today's date for user's current locale
 * @returns {string} - current date in YYYY-MM-DD format
 * (can't use ISOFormat because that returns UTC date)
 */
export const todayLocalDateYYYYMMDD = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

/**
 * dateRangeFilterFn - filter function for date columns
 * @param row - row object being evaluated for filtering (from which .value can be accessed for column)
 * @param columnId - id of column being evaluated for filtering
 * @param filterValue - array of date range to filter within [yyyy-mm-dd from-date string, yyyy-mm-dd to-date string]
 * @returns {boolean} - If true, include the row from display.  If false, exclude row from display.
 */
export const dateRangeFilterFn = (row, columnId, filterValue) => {
  const rowDateString = row.getValue(columnId);  // string: "YYYY-MM-DD" or '' or null
  if (!rowDateString) return (!filterValue[0] && !filterValue[1]);  // allow row with blank date unless a from-date is set

  // Exclude if date less than from-date
  if (filterValue[0] && rowDateString < filterValue[0]) return false;

  // Exclude if date greater than to-date
  return !(filterValue[1] && rowDateString > filterValue[1]);
}

/**
 * Convert database sample_type value to capitalized (or ALL CAPS) display value
 * @param st - lowercase value of sample_type from db
 * @returns string - display-value of given sample_type
 */
export const displaySampleType = st => {
  if (st === 'dna') return 'DNA';
  if (st === 'other') return st;
  if (st && st.length > 1) return st.charAt(0).toUpperCase() + st.slice(1); // capitalize
  return st;
}

/**
 * Convert database accessioning_type value to capitalized (or ALL CAPS) display value
 * @param at - lowercase value of accessioning_type from db
 * @returns string - display-value of given accessioning_type
 */
export const displayAccessioningType = at => {
  if (at === 'qc') return 'QC';
  if (at === 'unprocessed') return at;
  if (at === 'dna') return 'other DNA';  // DNA emoji is 🧬 if I ever want to use it
  if (at && at.length > 1) return at.charAt(0).toUpperCase() + at.slice(1); // capitalize
  return at;
}


/**
 * Component that creates hyperlink to given case
 * @param providerId - id of case's provider (clinic)
 * @param patientId - case id
 * @param caseId - GPCL id of case (display text)
 * @returns {JSX.Element}
 */
export const CaseLink = ({providerId, patientId, caseId}) => (
  <a href={`../patients/${patientId}?provider=${providerId}`}
     title="Open case in new window"
     target="_blank" rel="noopener noreferrer"
  >
    {caseId ? caseId : `#${patientId}`}
  </a>
);


/**
 * Get user token as deposited by CPV1 login.
 * @returns {string|null} - user's auth token.  Null if fails to retrieve.
 */
export const getUserToken = () => {
  const auth = JSON.parse(window.localStorage.getItem('persist:clinic-portal'));
  if (!auth) return null;

  const user = JSON.parse(auth.auth);
  if ('token' in user) return user.token;
  return null;
}


/**
 * Shortcut to construct parameters for axios for backend calls
 * @param {string} url - url to invoke.  Trailing slash added if not included.
 * @param {string} token - token returned from getAuthToken
 * @param {object|null} data - data, if applicable
 * @param {string} method - http method - 'GET', 'POST' or other.  'POST' is default.
 * @returns {object} - parameter object to use in axios call
 */
export const axiosParams = (url, token, data=null, method = 'POST') => {
  return {
    url: url.charAt(url.length - 1) !== '/' ? url + '/' : url,  // url must end in slash
    method: method,
    responseType: 'json',
    baseURL: process.env.REACT_APP_API_HOST,
    headers: {
      "Authorization": `Token ${token}`
    },
    data: data
  }
}

/**
 * Pop window to view file at given S3 url
 * @param {string} url
 */
export const viewS3File = url => {
  const token = getUserToken();
  if (!token) return;

  axios.post('/api/get-uploaded-files-presigned-url', {
    file_name: url
  }, {
    headers: {
      "Authorization": `Token ${token}`
    },
  }).then((response) => {
    window.open(response.data.presigned_url, '_blank')
  })
};

/**
 * Input control that pauses a half-second before processing user entry
 * @param initialValue
 * @param onChange - function to run whenever user entry changes
 * @param debounce - number of milliseconds to pause.  Default is half-second (500ms)
 * @param props - rest of input control's props, such as type, etc.
 * @returns {JSX.Element}
 */
export function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value]) // eslint-disable-line  react-hooks/exhaustive-deps

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}


// ShowChildren - show/hide children based on "when".  Nifty replacement for ? and && conditional code.
export function ShowChildren({ when, fallback=null, children }) { return when ? children : fallback; }

