import axios from "axios";
import {axiosParams, getUserToken} from "../../../common/utils";
import {ACCESSIONING_DRAFT_API_URL} from "./draft";

const deleteDraft = () => {
  return new Promise((resolve, reject) => {
    const token = getUserToken();
    if (!token) {
      reject(new Error("No user token available"));
      return;
    }

    axios(axiosParams(ACCESSIONING_DRAFT_API_URL, token, null, 'DELETE')
    ).then(() => resolve(true)
    ).catch((error) => {
        reject(error);
      });
  });
};

export default deleteDraft;